import "../styles/Footer.css";
import { Link } from "react-router-dom";
import React from "react";
import logo from "../../assets/svgs/logo_green.svg";


export default function Footer() {
  return (
    <div className="container-fluid bg-dark footer-container pt-5">
      <div className="container py-5">
        <div className="row">
          <div className="col-md-6">
            <span className="text-brand fs-2">
              <img src={logo} alt="conceptune" width={200}/>
            </span>
            <p className="text-brand">Innovating Tomorrow, Today.</p>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-6">
                <p>
                  <Link className="link-light link-offset-2 link-underline link-underline-opacity-0" to="/">Home</Link>
                </p>
                <p>
                  <Link className="link-light link-offset-2 link-underline link-underline-opacity-0" to="/about">About</Link>
                </p>
                <p>
                  <Link className="link-light link-offset-2 link-underline link-underline-opacity-0" to="/contact">Contact</Link>
                </p>
              </div>
              <div className="col-md-6">
                <p>
                  <Link className="link-light link-offset-2 link-underline link-underline-opacity-0" to="/privacy">Privacy Policy</Link>
                </p>
                <p>
                  <Link className="link-light link-offset-2 link-underline link-underline-opacity-0" to="/terms">Terms & conditions</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="border border-success"></div>
        <div className="mt-3">
          <span className="text-brand">Copyrights &copy; 2024 Conceptune | All rights reserved.</span>
        </div>
      </div>
    </div>
  );
}
