import React, { useEffect } from "react";

export default function PrivacyPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  return (
    <div className="container my-5">
      <div className="mb-5">
        <h1 className="fw-bold">Privacy Policy</h1>
        <p>
          Effective Date: 17<sup>th</sup> September, 2024
        </p>
        <p>
          <strong>Conceptune</strong> ("we," "our," or "us") values the privacy
          of our customers and users. This Privacy Policy explains how we
          collect, use, disclose, and protect your personal information when you
          use our website, software products, and services ("Services"). By
          accessing or using our Services, you consent to the practices
          described in this policy.
        </p>
      </div>
      <div className="d-flex flex-column gap-3 mb-5">
        <h5>Information We Collect</h5>
        <div>
          <i className="bi bi-patch-check-fill text-primary"></i>{" "}
          <span>
            <strong>Personal Information:</strong> Such as your name, email
            address, phone number, billing information, and other details that
            can identify you directly.
          </span>
        </div>
        <div>
          <i className="bi bi-patch-check-fill text-primary"></i>{" "}
          <span>
            <strong>Usage Information:</strong> Data regarding your interactions
            with our website and Services, including IP addresses, browser type,
            device information, and other data.
          </span>
        </div>
        <div>
          <i className="bi bi-patch-check-fill text-primary"></i>{" "}
          <span>
            <strong>Cookies and Tracking Technologies:</strong> We use cookies
            and similar tracking technologies to analyze user behavior and
            improve our Services. projects succeed.
          </span>
        </div>
      </div>
      <div className="d-flex flex-column gap-3 mb-5">
        <h5>How We Use Your Information</h5>
        <p>We may use your information to:</p>
        <div>
          <i className="bi bi-patch-check-fill text-primary"></i>{" "}
          <span>Provide and improve our products and services</span>
        </div>
        <div>
          <i className="bi bi-patch-check-fill text-primary"></i>{" "}
          <span>Process transactions and send relevant communications</span>
        </div>
        <div>
          <i className="bi bi-patch-check-fill text-primary"></i>{" "}
          <span>Respond to inquiries and provide customer support</span>
        </div>
        <div>
          <i className="bi bi-patch-check-fill text-primary"></i>{" "}
          <span>Analyze website usage for better user experience</span>
        </div>
        <div>
          <i className="bi bi-patch-check-fill text-primary"></i>{" "}
          <span>Comply with legal obligations</span>
        </div>
      </div>
      <div className="d-flex flex-column gap-3 mb-5">
        <h5>Sharing Your Information</h5>
        <p>
          We do not sell or rent your personal information to third parties. We
          may share information in the following circumstances:
        </p>
        <div>
          <i className="bi bi-patch-check-fill text-primary"></i>{" "}
          <span>
            With service providers who assist in delivering our Services (e.g.,
            payment processors, hosting services)
          </span>
        </div>
        <div>
          <i className="bi bi-patch-check-fill text-primary"></i>{" "}
          <span>
            To comply with legal obligations, enforce our Terms and Conditions,
            or protect our rights
          </span>
        </div>
        <div>
          <i className="bi bi-patch-check-fill text-primary"></i>{" "}
          <span>
            In connection with a business transfer, such as a merger or
            acquisition
          </span>
        </div>
      </div>
      <div className="d-flex flex-column gap-3 mb-5">
        <h5>Data Security</h5>
        <p>
          We take the protection of your data seriously and implement
          industry-standard security measures to safeguard your personal
          information. This includes:
        </p>
        <div>
          <i className="bi bi-patch-check-fill text-primary"></i>{" "}
          <span>
            <strong>Encryption:</strong> We encrypt sensitive data both at rest
            and during transmission to prevent unauthorized access.
          </span>
        </div>
        <div>
          <i className="bi bi-patch-check-fill text-primary"></i>{" "}
          <span>
            <strong>Secure Transmission:</strong> All data transmitted between
            your browser and our servers is encrypted using HTTPS, ensuring
            secure communication.
          </span>
        </div>
        <p>
          While we strive to protect your information, please be aware that no
          method of transmission over the Internet or method of electronic
          storage is 100% secure. Therefore, we cannot guarantee absolute
          security.
        </p>
      </div>
      <div className="d-flex flex-column gap-3 mb-5">
        <h5>Your Rights</h5>
        <p>
          Depending on your jurisdiction, you may have the right to access,
          correct, delete, or object to the processing of your personal
          information. To exercise these rights, please contact us at{" "}
          <strong>support@conceptune.com</strong>.
        </p>
      </div>
      <div className="d-flex flex-column gap-3 mb-5">
        <h5>Changes to This Privacy Policy</h5>
        <p>
          We may update this policy from time to time. Any changes will be
          posted on this page with an updated effective date.
        </p>
      </div>
      <div className="mb-5">
        <p>
          If you have any questions or concerns regarding this Privacy Policy,
          please contact us at <strong>support@conceptune.com</strong>.
        </p>
      </div>
    </div>
  );
}
