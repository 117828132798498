import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export default function NotFoundPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  return (
    <div className="h-100 text-center my-5">
      <div className="container d-flex justify-content-center align-items-center py-5">
        <h1 className="display-1">404</h1>
        <h2>- Page Not Found</h2>
      </div>
      <Link to="/">Back to Home</Link>
    </div>
  );
}
