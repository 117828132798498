import React, { useEffect } from "react";

export default function AboutPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  return (
    <div className="container my-5">
      <div className="mb-5">
        <h1 className="fw-bold">About Us</h1>
        <p>
          Welcome to <strong>Conceptune</strong>, a software development company driven by innovation and the desire to bring impactful ideas to life. Founded by{" "}
          <a className="text-brand link-offset-2 link-underline link-underline-opacity-0" href="https://technitish.com">
            Nitish Kumar
          </a>
          , Conceptune is dedicated to building high-quality software products and offering tailored software solutions for businesses and individuals. At Conceptune, we believe that great software can transform industries and elevate user experiences. Whether you're a startup looking to bring a new idea to market or an established company seeking to enhance your digital capabilities, we have the expertise to turn your vision into reality.
        </p>
      </div>
      <div className="d-flex flex-column gap-3 mb-5">
        <h5>What We Do:</h5>
        <div>
          <i className="bi bi-patch-check-fill text-primary"></i>{" "}
          <span>
            <strong>Software Product Development:</strong> We create cutting-edge software products that solve real-world problems and add value to businesses across various sectors.
          </span>
        </div>
        <div>
          <i className="bi bi-patch-check-fill text-primary"></i>{" "}
          <span>
            <strong>Custom Software Solutions:</strong> Every company has unique needs, and we work closely with our clients to build software tailored to their specific requirements.
          </span>
        </div>
        <div>
          <i className="bi bi-patch-check-fill text-primary"></i>{" "}
          <span>
            <strong>Consulting Services:</strong> Our team of experts is here to provide guidance and technical insight, ensuring that your software projects succeed.
          </span>
        </div>
      </div>
      <div className="mb-5">
        <h5>Our Mission:</h5>
        <p>At Conceptune, our mission is to empower businesses and individuals by delivering innovative and reliable software solutions. We are passionate about leveraging the latest technologies and industry best practices to help our clients stay ahead in an ever-evolving digital landscape.</p>
      </div>
      <div className="d-flex flex-column gap-3 mb-5">
        <div>
          <i className="bi bi-patch-check-fill text-primary"></i>{" "}
          <span>
            <strong>Customer-Centric Approach:</strong> We prioritize understanding your business objectives and challenges to deliver solutions that align with your goals.
          </span>
        </div>
        <div>
          <i className="bi bi-patch-check-fill text-primary"></i>{" "}
          <span>
            <strong>Technical Excellence:</strong> With expertise in modern technologies and methodologies, we ensure that every product and service we deliver meets the highest standards of quality.
          </span>
        </div>
        <div>
          <i className="bi bi-patch-check-fill text-primary"></i>{" "}
          <span>
            <strong>Collaborative Partnership:</strong> We believe in building lasting partnerships with our clients, working together through every step of the development process.
          </span>
        </div>
      </div>
      <div className="mb-5">
        <p>
          <span className="fw-bold fs-4">"</span>
          At Conceptune, we're not just building software, we're building solutions that inspire progress.
          <span className="fw-bold fs-4">"</span>
        </p>
      </div>
    </div>
  );
}
