import "../styles/Introduction.css";
import React, { useState } from "react";
import introImg from "../../assets/imgs/intro.jpg";
import patternSvg from  "../../assets/svgs/pattern.svg";
import scratchesSvg from  "../../assets/svgs/scratches.svg";
import introSub from "../../assets/imgs/intro-sub.jpg";
import { useNavigate } from "react-router-dom";

export default function Introduction() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');

  const toContactPage = () => {
    navigate(`/contact?email=${email}`);
  }

  return (
    <div className="container intro-container d-flex justify-content-start align-items-center position-relative mb-5">
      <div>
        <img className='position-absolute z-n1 top-0 img-fluid' src={patternSvg} alt="pattern" />
      </div>
      <div className="row">
        <div className="col-xl-6">
          <div className="intro-slogan">
            <h1>Innovating</h1>
            <h1>Tomorrow,</h1>
            <h1>Today.</h1>
          </div>
          <p>
            <span className="fw-bold fs-4">"</span>
            Transform your vision into reality with our cutting-edge software solutions — designed to innovate, optimize, and elevate your business.
            <span className="fw-bold fs-4">"</span>
          </p>
          <div className="email-container d-flex p-1 border rounded">
            <input className="flex-grow-1" type="email" name="email" id="email" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)}/>
            <button className="btn btn-primary" onClick={toContactPage}>Contact Us</button>
          </div>
        </div>
        <div className="col-xl-6 intro-right-col">
          <div className="d-flex justify-content-center align-items-center position-relative">
            <img className="intro-img-glom img-fluid rounded-5" src={introImg} alt="intro" />
            <img className="position-absolute scratch-svg" src={scratchesSvg} alt="scratches" />
            <img className="position-absolute rounded-5 intro-img-sub" src={introSub} alt="intro-sub" />
          </div>
        </div>
      </div>
    </div>
  );
}
