import "../styles/ExperienceBanner.css";
import React from 'react';
import codingImg from '../../assets/imgs/coding.jpg';

export default function ExperienceBanner() {
  return (
    <div className='container mb-5'>
        <div className="row">
            <div className="col-md-6">
                <div className="d-flex gap-3 mb-3">
                    <div className="exp-badge d-flex flex-column rounded-4 p-3 bg-dark">
                        <span className='fw-bold fs-3 text-light'>3+ year Experience</span>
                        <span className='text-light'>In Software Development</span>
                    </div>
                </div>
                <div>
                    <img className='exp-coding-img rounded-5' src={codingImg} alt='coding'/>
                </div>
            </div>
            <div className="col-md-6 d-flex flex-column justify-content-center">
                <div className="d-flex gap-2 mb-3">
                    <i className="bi bi-star-fill text-warning"></i><span className="fw-bold">Your gateway to digital success</span>
                </div>
                <div className="mb-3">
                    <span className="fs-1 fw-bold">Defining the future of online experiences!</span>
                </div>
                <div className="mb-3">
                    <span>Embrace a new era of digital success with Conceptune. Our team combines cutting-edge design with robust development to deliver websites that captivate and convert.</span>
                </div>
                <div className="d-flex flex-column gap-1 mb-3">
                    <div><i className="bi bi-patch-check-fill text-primary"></i> <span>Specializing in solutions that optimize return on investment.</span></div>
                    <div><i className="bi bi-patch-check-fill text-primary"></i> <span>An innovative team with broad-ranging proficiencies.</span></div>
                    <div><i className="bi bi-patch-check-fill text-primary"></i> <span>Proven ability to deliver impactful results.</span></div>
                </div>
            </div>
        </div>
    </div>
  )
}
