import { Link } from "react-router-dom";
import "../styles/Pricing.css";
import React from "react";

export default function Pricing() {
  return (
    <div className="container mb-5">
      <div className="text-center mb-5">
        <span className="p-2 rounded border shadow">
          💵 Transparent pricing packages
        </span>
      </div>
      <h2 className="text-center text-brand">
        Choose a solution that scales with your success.
      </h2>
      <div className="row mt-5">
        <div className="col-md-6">
          <h5>Features list of your need</h5>
          <div className="d-flex flex-column gap-3">
            <div>
              <i className="bi bi-patch-check-fill text-primary"></i>{" "}
              <span>
                <strong>Comprehensive Web Development:</strong> Offering
                custom-built static, dynamic, and single-page applications (SPA)
                tailored to your business needs.
              </span>
            </div>
            <div>
              <i className="bi bi-patch-check-fill text-primary"></i>{" "}
              <span>
                <strong>Mobile App Solutions:</strong> Designing seamless,
                high-performance mobile applications for iOS and Android
                platforms.
              </span>
            </div>
            <div>
              <i className="bi bi-patch-check-fill text-primary"></i>{" "}
              <span>
                <strong>AI Integration:</strong> Implementing cutting-edge AI
                capabilities, such as GPT-based models, to enhance interactivity
                and automate processes.
              </span>
            </div>
            <div>
              <i className="bi bi-patch-check-fill text-primary"></i>{" "}
              <span>
                <strong>Scalable Solutions:</strong> Crafting websites and
                applications that scale with your growing business, ensuring
                long-term success.
              </span>
            </div>
            <div>
              <i className="bi bi-patch-check-fill text-primary"></i>{" "}
              <span>
                <strong>Responsive Design:</strong> Building fully responsive
                websites and apps that deliver a consistent user experience
                across all devices.
              </span>
            </div>
            <div>
              <i className="bi bi-patch-check-fill text-primary"></i>{" "}
              <span>
                <strong>End-to-End Development:</strong> Providing full-cycle
                development services, from initial concept to deployment and
                ongoing support.{" "}
              </span>
            </div>
            <div>
              <i className="bi bi-patch-check-fill text-primary"></i>{" "}
              <span>
                <strong>Custom-Tailored Features:</strong> Integrating the
                latest technologies and functionalities based on your specific
                business requirements.{" "}
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="custom-price-container bg-dark rounded p-5">
            <div className="mb-3">
              <span className="p-2 rounded border border-success text-brand">
                Enterprise
              </span>
            </div>
            <h2 className="text-light fw-bold">Custom pricing plan</h2>
            <p className="text-light">Get personalized and custom plan</p>
            <Link className="btn btn-primary" to="/contact">Contact Us</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
