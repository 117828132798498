import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { isEmail, isPhone } from "../utils/validators";
import { getCookie } from "../utils/cookies";
import axios from "axios";
import { getToken } from "firebase/app-check";
import { appCheck } from "../../firebase.config";

export default function ContactPage() {
  const [queryParams] = useSearchParams();
  const [alert, setAlert] = useState<{ type: "success" | "danger"; message: string } | null>(null);
  const [loading, setLoading] = useState(false);
  const [contact, setContact] = useState({
    firstName: "",
    lastName: "",
    email: queryParams.has("email") ? queryParams.get("email")! : "",
    phone: "",
    type: "COMPANY",
    companyName: "",
    message: "",
  });

  const onContactChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setContact({ ...contact, [name]: value });
  };

  const isContactFormValid = () => {
    if (contact.firstName === "" || contact.lastName === "") {
      setAlert({ type: "danger", message: "First name and last name are required." });
      return false;
    }
    if (contact.email === "" || !isEmail(contact.email)) {
      setAlert({ type: "danger", message: "Email address is required." });
      return false;
    }
    if (contact.phone === "" || !isPhone(contact.phone)) {
      setAlert({ type: "danger", message: "Phone number is required." });
      return false;
    }
    if (contact.type === "COMPANY" && contact.companyName === "") {
      setAlert({ type: "danger", message: "Company name is required." });
      return false;
    }
    return true;
  };

  const onSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAlert(null);
    if (isContactFormValid()) {
      setLoading(true);
      const csrfToken = getCookie("csrftoken");
      const recaptchaTokenResult = await getToken(appCheck);
      axios
        .post("/api/contact/", contact, {
          method: "POST",
          headers: {
            "X-CSRFToken": csrfToken,
            "X-RECAPTCHAToken": recaptchaTokenResult.token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setAlert({ type: "success", message: "Thanks for contacting us. We will reply you soon." });
          } else {
            setAlert({ type: "danger", message: "Failed to send the message. Please try again later." });
          }
          setLoading(false);
        })
        .catch((error) => {
          setAlert({ type: "danger", message: "Failed to send the message. Please try again later." });
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="container my-5">
      <div className="mb-5">
        <h1 className="fw-bold">Contact Us</h1>
      </div>
      <form>
        {alert && (
          <div className={`alert alert-${alert.type}`} role="alert">
            {alert.message}
          </div>
        )}
        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="firstName" className="form-label">
                First Name
              </label>
              <input type="text" className="form-control" name="firstName" placeholder="Enter your first name" value={contact.firstName} onChange={onContactChange} required />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="lastName" className="form-label">
                Last Name
              </label>
              <input type="text" className="form-control" name="lastName" placeholder="Enter your last name" value={contact.lastName} onChange={onContactChange} required />
            </div>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email address
          </label>
          <input type="email" className="form-control" name="email" placeholder="Enter your email" value={contact.email} onChange={onContactChange} required />
        </div>
        <div className="mb-3">
          <label htmlFor="phone" className="form-label">
            Phone no.
          </label>
          <input type="number" className="form-control" name="phone" placeholder="Enter phone no." min={6000000000} max={9999999999} value={contact.phone} onChange={onContactChange} required />
        </div>
        <div className="mb-3">
          <label htmlFor="type" className="form-label">
            Company or Individual
          </label>
          <select className="form-select" name="type" value={contact.type} onChange={onContactChange}>
            <option value="COMPANY">Company</option>
            <option value="INDIVIDUAL">Individual</option>
          </select>
        </div>
        {contact.type === "COMPANY" && (
          <div className="mb-3">
            <label htmlFor="companyName" className="form-label">
              Company Name
            </label>
            <input type="text" className="form-control" name="companyName" placeholder="Enter your company name" value={contact.companyName} onChange={onContactChange} />
          </div>
        )}
        <div className="mb-3">
          <label htmlFor="message" className="form-label">
            Message
          </label>
          <textarea className="form-control" name="message" rows={4} placeholder="Write your message here" value={contact.message} onChange={onContactChange} required></textarea>
        </div>
        <button type="submit" className="btn btn-primary" onClick={onSubmit} disabled={loading}>
          {loading ? (
            <>
              <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
              <span role="status">{' '}Sending...</span>
            </>
          ) : (
            "Submit"
          )}
        </button>
      </form>
    </div>
  );
}
