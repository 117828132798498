import { Link } from "react-router-dom";
import React from "react";
import logo from "../../logo.svg";

export default function Navbar() {
  return (
    <nav className="navbar navbar-expand-lg bg-transparent">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <div className="d-flex align-items-center gap-2">
            <img src={logo} alt="Bootstrap" width="50" height="24" /> <span>Conceptune</span>
          </div>
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0"></ul>
          <div className="d-flex">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to="/">Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to="/about">About</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to="/contact">Contact</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to="/privacy">Privacy</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to="/terms">Terms</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}
