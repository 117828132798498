import { Link } from "react-router-dom";
import "../styles/OurServices.css";
import React from "react";

export default function OurServices() {
  return (
    <div className="services-container mb-5 p-5">
      <div className="text-center mb-5">
        <span className="bg-dark p-2 text-light rounded shadow">
          🚀 Innovative solutions, Measurable results
        </span>
      </div>
      <h2 className="text-center text-brand">
        We specialize in the following services
      </h2>
      <div className="container pt-5">
        <div className="row">
          <div className="col-md-4">
            <div className="card service-card bg-dark">
              <div className="service-logo bg-dark">
                <i className="bi bi-laptop text-brand"></i>
              </div>
              <div className="card-body">
                <h5 className="card-title text-brand"><i className="bi bi-patch-check-fill text-brand"></i> Web Development</h5>
                <p className="card-text text-light">
                  We build websites of all types—static, dynamic, and
                  single-page applications (SPA)—tailored to meet your needs.
                </p>
                <Link to="/contact" className="card-link link-success link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Contact now <i className="bi bi-arrow-right"></i></Link>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card service-card bg-dark">
              <div className="service-logo bg-dark">
                <i className="bi bi-phone text-brand"></i>
              </div>
              <div className="card-body">
                <h5 className="card-title text-brand"><i className="bi bi-patch-check-fill text-brand"></i> Mobile Development</h5>
                <p className="card-text text-light">
                    We specialize in building high-quality mobile applications designed for seamless performance and user experience.
                </p>
                <Link to="/contact" className="card-link link-success link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Contact now <i className="bi bi-arrow-right"></i></Link>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card service-card bg-dark">
              <div className="service-logo bg-dark">
                <i className="bi bi-stars text-brand"></i>
              </div>
              <div className="card-body">
                <h5 className="card-title text-brand"><i className="bi bi-patch-check-fill text-brand"></i> AI Development</h5>
                <p className="card-text text-light">
                    We integrate advanced AI capabilities, such as GPT models, customized to meet your specific requirements.
                </p>
                <Link to="/contact" className="card-link link-success link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Contact now <i className="bi bi-arrow-right"></i></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
