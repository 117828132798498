import React, { useEffect } from 'react'
import Introduction from '../components/Introduction'
import ExperienceBanner from '../components/ExperienceBanner'
import OurServices from '../components/OurServices'
import Pricing from '../components/Pricing'

export default function HomePage() {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  });

  return (
    <div>
      <Introduction/>
      <ExperienceBanner/>
      <OurServices/>
      <Pricing/>
    </div>
  )
}
