import React, { useEffect } from "react";

export default function TermsPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  return (
    <div className="container my-5">
      <div className="mb-5">
        <h1 className="fw-bold">Terms and Conditions</h1>
        <p>
          Effective Date: 17<sup>th</sup> September, 2024
        </p>
        <p>
        These Terms and Conditions ("Terms") govern your use of the website and Services provided by <strong>Conceptune</strong> ("we," "our," or "us"). By accessing or using our Services, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, you should not use our Services.
        </p>
      </div>
      <div className="d-flex flex-column gap-3 mb-5">
        <h5>Use of Services</h5>
        <div>
          <i className="bi bi-patch-check-fill text-primary"></i>{" "}
          <span>
          You may use our Services only in compliance with these Terms and applicable laws. Unauthorized use, including violating security measures or misusing our resources, is strictly prohibited.
          </span>
        </div>
        <div>
          <i className="bi bi-patch-check-fill text-primary"></i>{" "}
          <span>
          We reserve the right to suspend or terminate access to our Services at any time, for any reason, without notice.
          </span>
        </div>
      </div>
      <div className="d-flex flex-column gap-3 mb-5">
        <h5>Intellectual Property</h5>
        <div>
          <i className="bi bi-patch-check-fill text-primary"></i>{" "}
          <span>All content, including software, logos, and designs provided by Conceptune, is the exclusive property of Conceptune or its licensors. You may not reproduce, distribute, or use our content without prior written consent.</span>
        </div>
        <div>
          <i className="bi bi-patch-check-fill text-primary"></i>{" "}
          <span>You retain ownership of any intellectual property you create using our services, provided that it does not violate our Terms.</span>
        </div>
      </div>
      <div className="d-flex flex-column gap-3 mb-5">
        <h5>User Responsibilities</h5>
        <div>
          <i className="bi bi-patch-check-fill text-primary"></i>{" "}
          <span>
          You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
          </span>
        </div>
        <div>
          <i className="bi bi-patch-check-fill text-primary"></i>{" "}
          <span>
          You agree not to use our Services for unlawful purposes, including uploading harmful, illegal, or fraudulent content.
          </span>
        </div>
      </div>
      <div className="d-flex flex-column gap-3 mb-5">
        <h5>Limitation of Liability</h5>
        <div>
          <i className="bi bi-patch-check-fill text-primary"></i>{" "}
          <span>
          Our Services are provided "as is," and we make no warranties or guarantees regarding the reliability, accuracy, or suitability of the Services.
          </span>
        </div>
        <div>
          <i className="bi bi-patch-check-fill text-primary"></i>{" "}
          <span>
          To the fullest extent permitted by law, Conceptune shall not be liable for any damages, including direct, indirect, incidental, or consequential damages, arising from your use of or inability to use our Services.
          </span>
        </div>
      </div>
      <div className="mb-5">
        <h5>Governing Law</h5>
        <p>
        These Terms are governed by and construed in accordance with the laws of India. Any disputes arising from these Terms will be resolved in the courts of India.
        </p>
      </div>
      <div className="mb-5">
        <h5>Changes to Terms and Conditions</h5>
        <p>
        We may revise these Terms at any time. By continuing to use our Services after such changes, you agree to the revised Terms.
        </p>
      </div>
      <div className="mb-5">
        <p>
        If you have any questions or concerns regarding these Terms, please contact us at <strong>support@conceptune.com</strong>.
        </p>
      </div>
    </div>
  );
}
